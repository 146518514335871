//BRAND
export const name = 'AiUpStudy';
export const company = 'AiUpStudy';
export const websiteURL = 'https://aiupstudy.com';
export const serverURL = 'https://aiupstudy.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aiupstudy-45220.appspot.com/o/IMG-20240429-WA0030.jpg?alt=media&token=a72b89e4-e14d-4f21-a85c-86bdb20535d3';
export const razorpayEnabled = true;
export const paypalEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Silver Plan';
export const MonthCost = 200;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Gold Plan';
export const YearCost = 500;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Ramuk";
export const profession = 'AiUpStudy';
export const photoURL = 'https://firebasestorage.googleapis.com/v0/b/aiupstudy-45220.appspot.com/o/IMG-20240429-WA0030.jpg?alt=media&token=a72b89e4-e14d-4f21-a85c-86bdb20535d3';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_live_GFyuuVgccM43Ai";
export const razorpayPlanIdOne = "plan_O4jcmslk4YPsqC";
export const razorpayPlanIdTwo = "plan_O4jfpYkA3HGhbu";
